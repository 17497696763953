.baselinker-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    box-sizing: border-box;
}

.baselinker-nav-item {
    border-bottom: 2px solid white;
}

.active-nav {
    border-bottom: 2px solid red;
}