.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.nav {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    gap: 10px;
}

.type {
    display: flex;
    align-self: center;
}
