.container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin: 0px 30px;
}

.selectors-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
}

.select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-container label {
    font-weight: bold;
    font-size: 18px;
}

.select-container select {
    border: black 2px solid;
    border-radius: 5px;
    width: 150px;
}

.get-report-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.client-return-title {
    justify-self: center;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
}

.fetch-controllers {
    display: flex;
    gap: 20px;
}

.client-return-orders {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin: 10px;
}

.client-return-orders-color {
    background-color: rgb(199, 199, 199);
}

.client-return-order-container {
    display: grid;
}

.client-return-order {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows:  1fr;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
}

.client-return-order-id {
    align-self: center;
}

.client-return-products {
    display: grid;
    row-gap: 10px;
}

.client-return-products-items {
    display: grid;
    row-gap: 5px;
}

.client-return-product {
    display: grid;
    grid-template-columns: 50px 5fr 2fr 2fr 100px;
    align-items: center;
}

.client-return-product-filters {
    border-bottom: 1px solid black;
}

.client-return-product-checkbox {
    max-height: 20px;
}

.client-return-product-quantity {
    max-width: 40px;
    border: 1px black solid;
    text-align: center;
    justify-self: center;
}

.client-return-product-quantity-filter {
    justify-self: center;
}

.client-return-order-side {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
}

.client-return-order-checkbox {
    justify-self: center;
}

.client-return-product-quantity-static {
    justify-self: center;
}

.client-return-product-quantity-container {
    justify-self: center;
}

.report-nav-item {
    border-bottom: 2px solid white;
}

.report-nav-item-active {
    border-bottom: 2px solid red;
}
.report-nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-weight: bold;
    font-size: 20px;
}