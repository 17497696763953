@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
    }
  }


body {
  overflow-x: hidden;
}