.report-currency-items {
    display: grid;
    row-gap: 20px;
    box-sizing: border-box;
}

.report-currency {
    display: grid;
    box-sizing: border-box;
}

.report-currency-title {
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
}

.report-products {
    display: grid;
    grid-template-rows: auto auto;
    box-sizing: border-box;
    margin-left: 20px;
}

.report-product {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr 1fr;
    column-gap: 10px;
    padding: 2px;
    box-sizing: border-box;
}

.report-product-filters {
    background-color: rgb(174, 174, 174);
    border-radius: 5px;
    box-sizing: border-box;
}

.report-currency-total {
    display: grid;
    justify-content: right;
    /* border-top: 2px black solid; */
    /* border-bottom: 2px black solid; */
    margin: 5px 0;
    box-sizing: border-box;
}

.report-currency-total-result {
    background-color: rgb(180, 180, 180);
    padding: 3px;
    border-radius: 2px;
    margin: 5px 0px;
    font-weight: 500;
    box-sizing: border-box;
}

.report-country {
    margin-left: 20px;
}

.report-countries {
    display: grid;
    gap: 20px;
}