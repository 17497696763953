.loading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.loading-main-text {
    justify-self: right;
}

.loading-dots {
    justify-self: left;
}