.send {
    border: 2px black solid;
    border-radius: 10px;
    padding: 5px;
    align-self: center;
}

.savePrice {
    grid-area: 1 / 9 / 1 / 10;
    align-self: center;
    justify-self: center;
}

.currency {
    grid-area: 1 / 8 / 1 / 9;
    align-self: center;
    justify-self: center;
}

.productName {
    grid-area: 1 / 5 / 1 / 8;
    align-self: center;
}

.quantity {
    grid-area: 1 / 4 / 1 / 5;
    display: flex;
    align-self: center;
    justify-self: center;
}

.price {
    grid-area: 1 / 3 / 1 / 4;
    display: flex;
    align-self: center;
    justify-self: center;
}

.sku {
    grid-area: 1 / 2 / 1 / 3;
    display: flex;
    align-self: center;
    justify-self: center;
}

.checkbox {
    grid-area: 1 / 1 / 1 / 2;
}

.head {
    background-color: rgb(190, 190, 190);
    align-items: center;
    border-radius: 7px;
    box-shadow: 2px 2px 5px gray;
}

.navigation {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.selectAll {
    border: 2px black solid;
    padding: 3px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px gray;
    margin: 0 10px;
}
.selectAll:hover {
    box-shadow: 0 0 10px gray;
}

.row > div {
    overflow: hidden;
}

.rowStructure {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.row {
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 10px;
}

.content {
    padding: 30px 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navLeft {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-self: left;
}

.sendContainer {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-self: center;
}

.totalOrders {
    align-self: center;
    justify-self: right;
}

.tableName {
    border: 2px solid black;
    box-sizing: border-box;
}