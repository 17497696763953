.report-container {
    display: grid;
}

.report-type-download {
    padding: 5px;
    background-color: rgb(164, 164, 164);
    border-radius: 5px;
    color: rgb(63, 63, 189);
    box-shadow: 0 0 5px grey;
    transition: 0.3s;
}

.report-type-download:hover {
    box-shadow: 0 0 10px rgb(72, 72, 72);
}

.report-type {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 10px;
}