.container {
    display: flex;
}

.active {
    background-color: red;
    color: white;
}

.btn {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    max-width: fit-content;
}