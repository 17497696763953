.price_style {
    border: 2px solid black;
    box-sizing: border-box;
    width: 100%;
}

.skuStyling {
    border: 2px solid black;
    box-sizing: border-box;
    width: 100%;
}

.price_style::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.body_row {
    justify-items: start;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.price_button {
    border: 2px black solid;
    border-radius: 2px;
}

.darkRow {
    background-color: rgb(240, 240, 240);
}

.notActive {
    background-color: rgb(197, 69, 69);
    color: white;
}

.checkboxStyling {
    transform: scale(1.4);
}

.savePriceStyle {
    border: 2px black solid;
    padding: 5px;
    border-radius: 5px;
    align-self: center;
}